/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductList from "../components/productList"
import ProductCard from "../components/productCard"

const ProductPage = ({ data }) => {
  let products = []
  data.allContentfulProducer.edges.map(node => {
    node.node.products.map(prod => products.push(prod))
  })
  console.log(products)
  return (
    <Layout>
      <SEO title="Tuotteet" />
      <Styled.h1 sx={{ textAlign: "center" }}>Tuotteet</Styled.h1>
      <ProductList>
        {products.map((product, i) => (
          <ProductCard product={product} key={i} />
        ))}
      </ProductList>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query {
    allContentfulProducer(sort: { fields: [name] }) {
      edges {
        node {
          name
          products {
            name
            slug
            externalLink
            producer {
              name
            }
            image {
              fluid(maxHeight: 512, maxWidth: 256) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`
